<template>
  <div class="menu">
    <div class="container">
      <div class="row" ref="title">
        <div class="col-12 col-md-6">
          <h1>{{ tite }}</h1>
        </div>
        <div class="col-12 col-md-6 text-end">
          <VoiceHelper v-if="voiceOverPath" :videoPath="voiceOverPath" />
        </div>
      </div>
    </div>
    <div class="menu__wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-evenly">
            <div class="menu-scroll">
            <div class="menu-item__overlay"></div>
            <div
              class="menu-item__wrapper"
              v-for="item in navigation"
              :key="item.id"
              :ref="item.navQuery"
            >
              <div
                class="menu-item__bg"
                :class="{'completed': progressChecker(item.url)}"
                ref="menuItem"
              >
                <router-link :to="item.url" class="menu-item">
                  <div class="step">{{ item.step }}</div>
                  <div class="name">{{ item.name }}</div>
                  <div class="sub-name" v-if="item.subName">
                    {{ item.subName }}
                  </div>
                </router-link>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container px-0">
      <div class="row">
        <div class="col-6 col-md-1">
          <img class="img-fluid logo" src="/assets/images/logo.png" />
        </div>
      </div>
    </div>

    <footer>
      <div class="col-12 footer-links bg--white">
          © AstraZeneca
          <span>|</span>
          <a href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html" target="_blank">Legal notice and Terms of Use</a>
          <span>|</span>
          <a href="https://www.azprivacy.astrazeneca.com/europe/unitedkingdom/en/privacy-notices.html" target="_blank">Privacy Notice</a>
          <span>|</span>
          <a href="//policy.cookiereports.com/fe01e9b6-en-gb.html" class="CookieReportsLink">Cookie Policy</a>
          <span>|</span>          
          <a href="https://www.astrazeneca.com/our-company/Contact-us.html" target="_blank">Contact us</a>
      </div>
    </footer>
  </div>
</template>

<script>
import VoiceHelper from "../components/VoiceHelper.vue";
import { gsap } from "gsap";

export default {
  name: "Menu",
  components: {
    VoiceHelper,
  },
  data() {
    return {
      progressArray: [],
      currentItem: '',
    }
  },
  computed: {
    tite() {
      return this.$store.getters.content.menu.title;
    },
    navigation() {
      return this.$store.getters.content.menu.navigation;
    },
    voiceOverPath() {
      const currentStage = this.navigation.find(item => item.navQuery === this.currentItem)
      return currentStage ? currentStage.voiceOverFileName : false
    }
  },
  mounted() {
    if(this.progressArray.length > 0) {
      if(window.innerWidth > 576) {
        this.animateNextStage(this.progressArray.slice(-1))
      }
      setTimeout(() => {
        this.animateNextStage(this.currentItem)
      }, 750);
    } else {
      this.animateNextStage(this.currentItem)
    }


    gsap
      .timeline()
      .fromTo(this.$refs.title, { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(
        this.$refs.menuItem,
        { opacity: 0 },
        { opacity: 1, duration: 1, stagger: 0.15 },
        0
      );
  },
  methods: {
    progressChecker(url) {
      const contains = this.progressArray.some(element => {
        if (url.includes(element)) {
          return true;
        }
        return false;
      });
    return contains
    },
    animateNextStage(stage) {
    const overlay = document.querySelector(".menu-item__overlay");
      if(this.$refs[stage]) {
        this.$refs[stage][0].classList.value = this.$refs[stage][0].classList.value + ' active'
        overlay.classList = overlay.classList + " active";
        let position = this.$refs[stage][0].getBoundingClientRect()
        console.log(window.innerWidth)
        if(window.innerWidth <= 576) {
          document.querySelector(".menu-scroll").scrollLeft = position.x - 110
        } else {
          overlay.style.left = position.x + "px";
        }
      }
    }
  },
  created() {
    switch (this.$route.query.stage) {
      case "inbound":
        this.progressArray = [];
        this.currentItem = "inbound";
        break;
      case "formulation":
        this.progressArray = ['inbound'];
        this.currentItem = "formulation";
        break;
      case "qc":
        this.progressArray = ['inbound', 'formulation'];
        this.currentItem = "qc";
        break;
      case "packing":
        this.progressArray = ['inbound', 'formulation', 'qc'];
        this.currentItem = "packing";
        break;
      case "outbound":
        this.progressArray = ['inbound', 'formulation', 'qc', 'packing'];
        this.currentItem = "outbound";
        break;
      case "challenge":
        this.progressArray = ['inbound', 'formulation', 'qc', 'packing', 'outbound'];
        this.currentItem = "challenge";
    }
  }
};
</script>

<style lang="scss">

</style>